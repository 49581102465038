import axios from 'axios';

interface ContactFormEntryDoc {
  name: string;
  email: string;
  message: string;
  reCaptchaToken: string;
}

class FirebaseService {
  public async addContactFormEntry(data: ContactFormEntryDoc): Promise<void> {
    try {
      const { name, email, message, reCaptchaToken } = data;
      await axios.post('/addContactFormEntry/', {
        name,
        email,
        message,
        reCaptchaToken,
      });
    } catch (error) {
      throw error;
    }
    return;
  }
}

export default FirebaseService;
