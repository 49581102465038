import { FormEvent, FunctionComponent } from 'react';
import React, { useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import FormField from '../components/form-field';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { useFormInput } from '../hooks/use-form-input';
import ErrorHandler from '../services/error-handler';
import FirebaseService from '../services/firebase.service';

const reCaptchaContactFormAction = 'contact_form';

function validateName(value: string): [boolean, string[]] {
  const validationMessages = [];
  if (value.length < 2) {
    validationMessages.push(`Te kort!`);
  }
  if (value.length > 50) {
    validationMessages.push(`Te lang!`);
  }
  return [validationMessages.length === 0, validationMessages];
}

const ContactForm: FunctionComponent = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const name = useFormInput<string>('' /*, validateName*/);
  const email = useFormInput<string>('');
  const message = useFormInput<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<boolean>(false);

  const nameIsValid = name.validation.isValid;
  const formIsValid = name.validation.isValid;
  const nameMessages = name.validation.validationMessages;

  const firebaseService = new FirebaseService();

  async function handleSubmit(event: FormEvent<HTMLFormElement>) {
    try {
      setLoading(true);
      event.preventDefault();
      if (!executeRecaptcha) {
        throw new Error('recaptcha not available!');
      }
      const reCaptchaToken = await executeRecaptcha(reCaptchaContactFormAction);
      if (!reCaptchaToken) {
        throw new Error('ReCaptcha token is undefined');
      }
      await firebaseService.addContactFormEntry({
        name: name.input.value,
        email: email.input.value,
        message: message.input.value,
        reCaptchaToken,
      });
      setLoading(false);
      setSuccess(true);
    } catch (error) {
      ErrorHandler.handleError(error);
      setLoading(false);
      setError(
        'Jouw bericht kon niet verzonden worden. Gelieve na te kijken of alle velden correct ingevuld zijn.',
      );
    }
  }
  return (
    <>
      {loading ? (
        <div>Jouw bericht wordt verzonden...</div>
      ) : success ? (
        <div>
          Bedankt voor jouw bericht. We verzenden binnenkort ons antwoord naar
          jouw e-mailadres <b>{email.input.value}</b>.
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          {!nameIsValid &&
            nameMessages.map(nm => <div key={`name_${nm}`}>{nm}</div>)}
          <FormField id="name" labelChildren="Jouw naam:">
            {id => <input id={id} type="text" {...name.input} />}
          </FormField>
          <FormField id="email" labelChildren="Jouw e-mailadres:">
            {id => <input id={id} type="email" {...email.input} />}
          </FormField>
          <FormField id="message" labelChildren="Jouw bericht:">
            {id => <textarea id={id} {...message.input} />}
          </FormField>
          <div>
            <button type="submit" disabled={!formIsValid || loading}>
              Verzenden
            </button>
          </div>
          {error && <div>{error}</div>}
          <div className="recaptcha-branding">
            Deze website is beveiligd met reCAPTCHA. Het{' '}
            <a href="https://policies.google.com/privacy" target="_blank">
              Privacybeleid
            </a>{' '}
            en de{' '}
            <a href="https://policies.google.com/terms" target="_blank">
              Servicevoorwaarden
            </a>{' '}
            van Google gelden.
          </div>
        </form>
      )}
    </>
  );
};

const Contact: FunctionComponent = () => {
  return (
    <Layout>
      <SEO title="Contact" />
      <h1>Contact</h1>
      <ContactForm />
    </Layout>
  );
};

export default Contact;
