import { FormEvent, useState } from 'react';

interface InputValueAndChangeHandler<T> {
  value: T;
  onChange: (event: FormEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

interface Validation {
  isValid?: boolean;
  validationMessages: string[];
}

interface ReturnValue<T> {
  input: InputValueAndChangeHandler<T>;
  validation: Validation;
}

export function useFormInput<T>(
  initialValue: T,
  validator?: (value: any) => [boolean, string[]],
): ReturnValue<T> {
  const [value, setValue] = useState<T>(initialValue);
  const [validation, setValidation] = useState<Validation>({
    isValid: true,
    validationMessages: [],
  });

  function handleChange(
    event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) {
    const newValue = (event.target as any).value;
    setValue(newValue);
    if (validator) {
      const [isValid, validationMessages] = validator(newValue);
      setValidation({
        isValid,
        validationMessages,
      });
    }
  }

  const returnValue: ReturnValue<T> = {
    input: {
      value,
      onChange: handleChange,
    },
    validation,
  };

  return returnValue;
}
