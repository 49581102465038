import { FunctionComponent, ReactNode } from 'react';
import React from 'react';

interface Props {
  id: string;
  labelChildren: ReactNode;
  children: (id: string) => ReactNode;
}

const FormField: FunctionComponent<Props> = ({
  id,
  labelChildren,
  children,
}) => (
  <div className="form-field">
    <label htmlFor={id}>{labelChildren}</label>
    {children(id)}
  </div>
);

export default FormField;
